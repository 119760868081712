import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import CarouselImages from "../components/CarouselImages";
import MultiImages from "../components/MultiImages";
import { AirbnbButton } from "../components/AirbnbButton";
import { BookingButton } from "../components/BookingButton";
import Img from "../components/Image";

export const ApartmentTemplate = ({
  title,
  description,
  address,
  info,
  gallery,
  airbnbLink,
  bookingLink,
}) => {
  return (
    <section className="section">
      <Container>
        <Row className="d-md-flex d-none">
          {gallery ? <MultiImages gallery={gallery} /> : null}
        </Row>
        {gallery ? <CarouselImages gallery={gallery} /> : null}
        <Row className="mt-5 mb-5">
          <Col md="7" lg={{ size: 6, offset: 1 }} xl={{ size: 6, offset: 1 }}>
            <h1>{title}</h1>
            <address>
              <p>
                {address.street}
                {address.street && address.city ? ", " : null}
                {address.city}
              </p>
            </address>
            <div style={{ display: "flex" }}>
              {info.guests ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0.5em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {info.icon1 ?
                    <Img imageInfo={info.icon1} />
                    : null}
                  <span
                    style={{
                      marginTop: "5px",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    {info.guests ? info.guests + " guests" : 0}{" "}
                  </span>
                </div>
              ) : null}
              {info.beds ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0.5em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {info.icon2 ?
                    <Img imageInfo={info.icon2} />
                    : null}
                  <span
                    style={{
                      marginTop: "5px",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    {info.beds ? info.beds + " beds" : 0}{" "}
                  </span>
                </div>
              ) : null}
              {info.wifi ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0.5em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {info.icon3 ?
                    <Img imageInfo={info.icon3} />
                    : null}
                  <span
                    style={{
                      marginTop: "5px",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    Wifi
                  </span>
                </div>
              ) : null}
              {info.kitchen ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0.5em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {info.icon4 ?
                    <Img imageInfo={info.icon4} />
                    : null}
                  <span
                    style={{
                      marginTop: "5px",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    Kitchen
                  </span>
                </div>
              ) : null}
            </div>
            <p>{description}</p>
          </Col>
          <Col md="5" lg={{ size: 4 }} xl={{ size: 4 }}>
            {airbnbLink ? <AirbnbButton link={airbnbLink} /> : null}
            {bookingLink ? <BookingButton link={bookingLink} /> : null}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

ApartmentTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  address: PropTypes.object,
  info: PropTypes.object,
  gallery: PropTypes.object,
  helmet: PropTypes.object,
};

export default ApartmentTemplate;
